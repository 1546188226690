.profile {
    position: relative;
    text-align: center;
    justify-content: center;
    transition: all 0.5s;
    align-items: center;
}

.profile div {
    width: 10vw;
    transition: all 0.5s;
    height: 10vw;
    margin: 0px;
    background-color: white;
    border-radius: 100%;
    border: 2px solid black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.4));
    filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.4))
}

.profile div span {
    position: absolute;
    top: 0%;
    transition: all 0.5s;
    width: 18px;
    height: 18px;
    left: 95%;
    font-size: 1rem;
    border-radius: 100%;
    font-weight: bold;
    background-color: rgb(0, 0, 0,0.2);
    box-shadow: inset 0 0 10px rgb(0, 0, 0,0.3);
    color: black;
}

.profile p {
    width: 100%;
    height: auto;
    padding: 1px 0px;
    transition: all 0.5s;
    border-radius: 20px;
    font-size: 0.6rem;
    background-color: rgb(0, 0, 0,0.2);
    box-shadow: inset 0 0 10px rgb(0, 0, 0,0.3);
    color: rgb(0, 0, 0);
}

@media (min-width: 850px) {
    .profile div {
        width: 7vw;
        height: 7vw;
    }
}

@media (min-width: 1200px) {
    .profile div {
        width: 80px;
        height: 80px;
    }

    .profile p {
        font-size: 0.8rem;
    }
}