html,
body {
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
}

.grid {
  display: grid;
  grid-template-columns: repeat (3, 1fr);
  grid-gap: 1em;
}

.grid>* {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  justify-content: center;
}

.col {
  margin: 2px;
}

.text-center {
  text-align: center;
}

.align-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.players {
  font-family: Hack, monospace;
  color: rgb(122, 122, 122);
  cursor: pointer;
  font-size: 1em;
  font-weight: bolder;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  padding: 0.8rem 0.6rem;
  text-align: center;
  border: 2px solid rgb(122, 122, 122);
  transition: all 0.5s;
  border-radius: 5px;
  width: auto;
  position: relative;
}

.btn {
  font-family: Hack, monospace;
  background: linear-gradient(to bottom left, #F454FF, #6B4BFE);
  color: #ffffff;
  cursor: pointer;
  font-size: 0.7em !important;
  padding: 0.5rem;
  border: 0;
  transition: all 0.1s;
  border-radius: 5px;
  width: auto;
  -webkit-filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.2));
  position: relative;

}

.btn::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  position: absolute;
  opacity: 0;
}

.btn:hover {
  scale: 1.1;
  background: hsl(242deg 50% 25%);
  -webkit-filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(7px 7px 7px rgba(0, 0, 0, 0.4));
}

.btn:hover::after {
  scale: 1.1;
  opacity: 1;
}
.css-1y942vo-MuiButtonBase-root-MuiButton-root  {
  font-weight: bold !important;
}
@media (min-width: 850px) {
  .players {
    padding: 1rem 0.6rem;
  }
}

@media (min-width: 1200px) {
  .players {
    padding: 1.2rem 0.8rem;
  }
}